import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    overflow: "hidden",
    width: "100%",
    height: "100%",
    opacity: ({ isVisible }) => (isVisible ? 1 : 0),
    transition: "opacity .1s",
    transitionDelay: ({ isVisible }) => (isVisible ? ".2s" : "0s"),
    display: "grid",
    gridTemplateRows: "4fr 6fr",
    gridTemplateColumns: "auto",
    [theme.breakpoints.up(767)]: {
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "auto",
    },
  },
  imageArea: {
    //border: "2px solid blue",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    [theme.breakpoints.up(767)]: {
      justifyContent: "flex-start",
      alignItems: "center",
    },
    cursor: "pointer",
  },
  image: {
    // border: "3px solid red",
    backgroundImage: ({ img }) => `url('${img}')`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 400,
    height: 600,
    [theme.breakpoints.down(767)]: {
      width: 300,
      height: 450,
      // transform: "scale(.9)",
    },
  },
  textArea: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: theme.palette.primary[800],
    textAlign: "center",
    fontSize: 12,
    paddingTop: 120,
    paddingLeft: 0,
    [theme.breakpoints.up(767)]: {
      fontSize: 30,
      // paddingLeft: 100,
      paddingTop: 0,
    },
    cursor: "pointer",
  },
  title: {
    fontSize: 24,
    [theme.breakpoints.up(767)]: {
      fontSize: 35,
      margin: 20,
    },
    letterSpacing: 15,
    fontWeight: 500,
    margin: 15,
  },
  designElem: {
    width: 10,
    height: 10,
    background: theme.palette.primary[800],
    transform: "rotate(45deg)",
    [theme.breakpoints.up(767)]: {
      margin: 20,
    },
    margin: 10,
  },
}));

const MiddlePage = ({ i, img, title, onClick }) => {
  const [isVisible, setIsVisible] = useState(true);
  const index = useSelector(state => state.landing.index);
  const classes = useStyles({ i, isVisible, img });

  useEffect(() => {
    setIsVisible(i === index);
  }, [i, index]);

  return (
    <div className={classes.root}>
      <div className={classes.textArea} onClick={onClick}>
        <div className={classes.pageIndex}>
          &bull; &nbsp;0{i + 1}&nbsp; &bull;
        </div>
        <div className={classes.title}>{title}</div>
        <div className={classes.designElem}></div>
      </div>
      <div className={classes.imageArea} onClick={onClick}>
        <div className={classes.image}></div>
      </div>
    </div>
  );
};

export default MiddlePage;
