import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AuthLayout from "../elements/authLayouts/AuthLayout";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    overflow: "hidden",
  },
  container: {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Auth = () => {
  const classes = useStyles();
  const displayName = useSelector(state => state.firebase.profile.displayName);

  if (displayName) {
    return <Redirect to="/admin" />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <AuthLayout />
      </div>
    </div>
  );
};

export default Auth;
