import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { onMenuClick } from "../../../../store/actions/landingActions";
import MenuIcon from "../../../Icons/MenuIcon";
import { ButtonBase } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  menuButton: {
    zIndex: 1001,
    position: "absolute",
    top: "100%",
    right: "50%",
    transform: "translate(50% , -100%)",
    [theme.breakpoints.up(767)]: {
      top: "50%",
      right: 0,
      transform: "translate(0, -50%)",
      width: 130,
      height: 90,
    },
    background: theme.palette.primary.main,
    color: "white",

    width: 100,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const MenuButton = () => {
  const isMenuOpen = useSelector(store => store.landing.isMenuOpen);
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleMenuClick = () => dispatch(onMenuClick());
  return (
    <ButtonBase className={classes.menuButton} onClick={handleMenuClick}>
      <MenuIcon open={isMenuOpen} fontSize="large" />
    </ButtonBase>
  );
};

export default MenuButton;
