import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    overflow: "hidden",
    width: "100%",
    height: "100%",
    // border: "2px solid green",
    opacity: ({ isVisible }) => (isVisible ? 1 : 0),
    transition: "opacity .1s",
    transitionDelay: ({ isVisible }) => (isVisible ? ".2s" : "0s"),
  },
  container: {
    width: "100%",
    height: "100%",
    // border: "2px solid red",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    //fontFamily: "Poppins",
    letterSpacing: 10,
    fontSize: 50,
    [theme.breakpoints.up(767)]: {
      letterSpacing: 15,
      fontSize: 110,
    },

    color: theme.palette.primary[800],
    textAlign: "center",
    fontWeight: 500,
  },
  small: {
    textAlign: "center",
    fontSize: 14,
    [theme.breakpoints.up(767)]: {
      fontSize: 20,
    },
    letterSpacing: 20,
    fontWeight: 200,
  },
}));

const FirstPage = ({ i }) => {
  const [isVisible, setIsVisible] = useState(true);
  const index = useSelector(state => state.landing.index);
  const classes = useStyles({ isVisible });

  useEffect(() => {
    setIsVisible(i === index);
  }, [i, index]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div>MELVIN</div>
        <div className={classes.small}>pizzeria</div>
      </div>
    </div>
  );
};

export default FirstPage;
