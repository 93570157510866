import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Home from "./routes/Home";
import Menu from "./routes/Menu";
import Order from "./routes/Order";
import Contact from "./routes/Contact";
import B2B from "./routes/B2B";
import Admin from "./routes/Admin";
import Auth from "./routes/Auth";

import Overlays from "./elements/overlays";
import { useFirebaseConnect } from "react-redux-firebase";

const Melvin = () => {
  let location = useLocation();

  useFirebaseConnect({
    path: `/menu`,
    storeAs: "menu",
  });

  return (
    <TransitionGroup>
      <Overlays />
      <CSSTransition
        key={location.key}
        classNames="page"
        timeout={300}
        unmountOnExit
      >
        <Switch location={location}>
          <Route exact path="/menu" component={Menu} />
          <Route exact path="/order" component={Order} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/b2b" component={B2B} />
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/auth" component={Auth} />
          <Route path="/" component={Home} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default Melvin;
