import { DEV } from "../../config/firebaseConfig";
// import React from "react";
// import { Button } from "@material-ui/core";
export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export const enqueueSnackbar = notification => {
  const key = notification.options && notification.options.key;

  return dispatch => {
    dispatch({
      type: ENQUEUE_SNACKBAR,
      notification: {
        ...notification,
        key: key || new Date().getTime() + Math.random(),
      },
    });
  };
};

export const closeSnackbar = key => {
  DEV && console.log("->>>", key);
  return dispatch => {
    dispatch({
      type: CLOSE_SNACKBAR,
      dismissAll: !key, // dismiss all if no key has been defined
      key,
    });
  };
};

export const removeSnackbar = key => {
  return dispatch => {
    dispatch({
      type: REMOVE_SNACKBAR,
      key,
    });
  };
};

//FCM
export const onSubscribe = ({ firebase, firestore }) => {
  return (dispatch, getState) => {
    //const firebase = getFirebase();
    //const firestore = getFirestore();
    const messaging = firebase.messaging();
    const uid = getState().firebase.auth.uid;
    const FCMtokens = getState().firebase.profile.FCMtokens;

    DEV && console.log("[onSubscribe]");

    messaging
      .requestPermission()
      .then(() => {
        DEV && console.log("have pemissioin!");
        return messaging.getToken();
      })
      .then(token => {
        // DEV &&  console.log(token);
        //const FCMtokens = store.firebase.profile.FCMtokens;
        DEV && console.log(token, FCMtokens);
        let tokenArr = [];
        tokenArr.push(token);
        //if user FCMtoken(s)
        if (!!FCMtokens && FCMtokens.length) {
          //if our token is not saved in db
          if (!FCMtokens.includes(token)) {
            tokenArr.push(FCMtokens[0]);
          } else {
            return "token already exist in db";
          }
        }
        //save token to db
        return firestore
          .collection("Users")
          .doc(uid)
          .set({ FCMtokens: tokenArr }, { merge: true });
      })
      .then(msg => DEV && console.log("FCM" + !msg ? "token added to db" : msg))
      .catch(err => DEV && console.log("FCM error ocured", err));
  };
};

export const onUnsubscribe = ({ firebase, firestore }) => {
  return (dispatch, getState) => {
    const messaging = firebase.messaging();
    const uid = getState().firebase.auth.uid;

    DEV && console.log("[onUnsubscribe]");
    messaging
      .getToken()
      .then(token => {
        //messaging.deleteToken(token);
        return token;
      })
      .then(token => {
        //delete token from firestore
        return firestore
          .collection("Users")
          .doc(uid)
          .update({
            FCMtokens: firebase.firestore.FieldValue.arrayRemove(token),
          });
      })
      .then(msg => DEV && console.log("token removed from database", msg))
      .catch(err => DEV && console.log("error ocured", err));
  };
};

export const onNewTokenEvent = ({ firebase, firestore }) => {
  //DEV &&  console.log("[tokenRefreshEvent Activated]");
  return (dispatch, getState) => {
    const store = getState();
    //const messaging = null;
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();

      messaging.onTokenRefresh(() => {
        DEV && console.log("[onTokenRefresh");
        return messaging.getToken().then(token => {
          const { FCMtokens } = store.firebase.profile;
          let tokenArr = [];
          tokenArr.push(token);
          //if user FCMtoken(s)
          if (!!FCMtokens && FCMtokens.length) {
            //if our token is not saved in db
            if (!FCMtokens.includes(token)) {
              tokenArr.push(FCMtokens[0]);
            } else {
              return "token already exist in db";
            }
          }
          //save token to db
          return firestore
            .collection("Users")
            .doc(store.firebase.profile.uid)
            .set({ FCMtokens: tokenArr }, { merge: true });
        });
      });
    }
  };
};

export const sendNotification = (message, { firestore }) => {
  DEV && console.log("sendNotification");
  return (dispatch, getState) => {
    const store = getState();
    const { displayName } = store.firebase.profile;

    return firestore
      .collection("fcm")
      .add({
        user: displayName,
        message,
        userProfileImg: "https://receptie.net/logoOG.png",
      })
      .then(() => DEV && console.log("added to db"))
      .catch(err => DEV && console.log(err.message));
  };
};
