export const OPEN_REFERRAL_DIALOG = "OPEN_REFERRAL_DIALOG";
export const CLOSE_REFERRAL_DIALOG = "CLOSE_REFERRAL_DIALOG";
export const OPEN_STATISTICS_DIALOG = "OPEN_STATISTICS_DIALOG";
export const CLOSE_STATISTICS_DIALOG = "CLOSE_STATISTICS_DIALOG";
export const OPEN_SIGNUP_DIALOG = "OPEN_SIGNUP_DIALOG";
export const CLOSE_SIGNUP_DIALOG = "CLOSE_SIGNUP_DIALOG";
export const OPEN_EDITSTATUS_DIALOG = "OPEN_EDITSTATUS_DIALOG";
export const CLOSE_EDITSTATUS_DIALOG = "CLOSE_EDITSTATUS_DIALOG";
//export const OPEN_EDITAVATAR_DIALOG = "OPEN_EDITAVATAR_DIALOG";
//export const CLOSE_EDITAVATAR_DIALOG = "CLOSE_EDITAVATAR_DIALOG";
export const OPEN_CREATEAPP_DIALOG = "OPEN_CREATEAPP_DIALOG";
export const CLOSE_CREATEAPP_DIALOG = "CLOSE_CREATEAPP_DIALOG";
export const OPEN_WARNING_DIALOG = "OPEN_WARNING_DIALOG";
export const CLOSE_WARNING_DIALOG = "CLOSE_WARNING_DIALOG";
export const OPEN_AUTH_DIALOG = "OPEN_AUTH_DIALOG";
export const CLOSE_AUTH_DIALOG = "CLOSE_AUTH_DIALOG";

export const OPEN_SERVICE_INFO_DIALOG = "OPEN_SERVICE_INFO_DIALOG";
export const CLOSE_SERVICE_INFO_DIALOG = "CLOSE_SERVICE_INFO_DIALOG";
export const OPEN_SEND_NOTIFICATIONS_DIALOG = "OPEN_SEND_NOTIFICATIONS_DIALOG";
export const CLOSE_SEND_NOTIFICATIONS_DIALOG =
  "CLOSE_SEND_NOTIFICATIONS_DIALOG";
export const OPEN_MYSCHEDULE_DIALOG = "OPEN_MYSCHEDULE_DIALOG";
export const CLOSE_MYSCHEDULE_DIALOG = "CLOSE_MYSCHEDULE_DIALOG";
export const OPEN_EDITSCHEDULE_DIALOG = "OPEN_EDITSCHEDULE_DIALOG";
export const CLOSE_EDITSCHEDULE_DIALOG = "CLOSE_EDITSCHEDULE_DIALOG";
export const OPEN_EDITGALLERY_DIALOG = "OPEN_EDITGALLERY_DIALOG";
export const CLOSE_EDITGALLERY_DIALOG = "CLOSE_EDITGALLERY_DIALOG";
export const OPEN_EDITPROFILEPHOTO_DIALOG = "OPEN_EDITPROFILEPHOTO_DIALOG";
export const CLOSE_EDITPROFILEPHOTO_DIALOG = "CLOSE_EDITPROFILEPHOTO_DIALOG";
export const OPEN_EDITPROFILE_DIALOG = "OPEN_EDITPROFILE_DIALOG";
export const CLOSE_EDITPROFILE_DIALOG = "CLOSE_EDITPROFILE_DIALOG";
export const OPEN_EDIT_THEME_DIALOG = "OPEN_EDIT_THEME_DIALOG";
export const CLOSE_EDIT_THEME_DIALOG = "CLOSE_EDIT_THEME_DIALOG";
export const OPEN_ONBOOKREQUEST_DIALOG = "OPEN_ONBOOKREQUEST_DIALOG";
export const CLOSE_ONBOOKREQUEST_DIALOG = "CLOSE_ONBOOKREQUEST_DIALOG";
export const OPEN_BOOKREQUEST_DIALOG = "OPEN_BOOKREQUEST_DIALOG";
export const CLOSE_BOOKREQUEST_DIALOG = "CLOSE_BOOKREQUEST_DIALOG";
export const OPEN_ACCOUNT_DIALOG = "OPEN_ACCOUNT_DIALOG";
export const CLOSE_ACCOUNT_DIALOG = "CLOSE_ACCOUNT_DIALOG";
export const OPEN_DRAWER = "OPEN_DRAWER";
export const CLOSE_DRAWER = "CLOSE_DRAWER";
export const OPEN_APPOINTMENT_INFO_DIALOG = "OPEN_APPOINTMENT_INFO_DIALOG";
export const CLOSE_APPOINTMENT_INFO_DIALOG = "CLOSE_APPOINTMENT_INFO_DIALOG";
export const OPEN_NEW_APPOINTMENT_DIALOG = "OPEN_NEW_APPOINTMENT_DIALOG";
export const CLOSE_NEW_APPOINTMENT_DIALOG = "CLOSE_NEW_APPOINTMENT_DIALOG";
export const OPEN_ADDTO_AGENDA_DIALOG = "OPEN_ADDTO_AGENDA_DIALOG";
export const CLOSE_ADDTO_AGENDA_DIALOG = "CLOSE_ADDTO_AGENDA_DIALOG";
export const CLOSE_ALL_DIALOGS = "CLOSE_ALL_DIALOGS";
export const OPEN_TERMS_DIALOG = "OPEN_TERMS_DIALOG";
export const CLOSE_TERMS_DIALOG = "CLOSE_TERMS_DIALOG";
export const OPEN_PRIVACY_DIALOG = "OPEN_PRIVACY_DIALOG";
export const CLOSE_PRIVACY_DIALOG = "CLOSE_PRIVACY_DIALOG";
export const OPEN_INSTALL_APP_DIALOG = "OPEN_INSTALL_APP_DIALOG";
export const CLOSE_INSTALL_APP_DIALOG = "CLOSE_INSTALL_APP_DIALOG";
export const OPEN_INSTALL_APPIOS_DIALOG = "OPEN_INSTALL_APPIOS_DIALOG";
export const CLOSE_INSTALL_APPIOS_DIALOG = "CLOSE_INSTALL_APPIOS_DIALOG";

export const openInstallAppIosDialog = () => {
  return dispatch => {
    dispatch({
      type: OPEN_INSTALL_APPIOS_DIALOG,
    });
  };
};
export const closeInstallAppIosDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_INSTALL_APPIOS_DIALOG,
    });
  };
};

export const openInstallAppDialog = () => {
  return dispatch => {
    dispatch({
      type: OPEN_INSTALL_APP_DIALOG,
    });
  };
};
export const closeInstallAppDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_INSTALL_APP_DIALOG,
    });
  };
};
export const openPrivacyDialog = () => {
  return dispatch => {
    dispatch({
      type: OPEN_PRIVACY_DIALOG,
    });
  };
};
export const closePrivacyDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_PRIVACY_DIALOG,
    });
  };
};
export const openTermsDialog = () => {
  return dispatch => {
    dispatch({
      type: OPEN_TERMS_DIALOG,
    });
  };
};
export const closeTermsDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_TERMS_DIALOG,
    });
  };
};

export const closeAllDialogs = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_ALL_DIALOGS,
    });
  };
};
export const closeReferralDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_REFERRAL_DIALOG,
    });
  };
};
export const openReferralDialog = () => {
  return dispatch => {
    dispatch({
      type: OPEN_REFERRAL_DIALOG,
    });
  };
};
export const closeStatisticsDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_STATISTICS_DIALOG,
    });
  };
};
export const openStatisticsDialog = () => {
  return dispatch => {
    dispatch({
      type: OPEN_STATISTICS_DIALOG,
    });
  };
};
export const closeAddToAgendaDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_ADDTO_AGENDA_DIALOG,
    });
  };
};
export const openAddToAgendaDialog = () => {
  return dispatch => {
    dispatch({
      type: OPEN_ADDTO_AGENDA_DIALOG,
    });
  };
};
export const closeNewAppointmentDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_NEW_APPOINTMENT_DIALOG,
    });
  };
};
export const openNewAppointmentDialog = () => {
  return dispatch => {
    dispatch({
      type: OPEN_NEW_APPOINTMENT_DIALOG,
    });
  };
};

export const closeAppointmentInfoDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_APPOINTMENT_INFO_DIALOG,
    });
  };
};
export const openAppointmentInfoDialog = info => {
  return dispatch => {
    dispatch({
      type: OPEN_APPOINTMENT_INFO_DIALOG,
      info,
    });
  };
};
export const closeDrawer = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_DRAWER,
    });
  };
};
export const openDrawer = () => {
  return dispatch => {
    dispatch({
      type: OPEN_DRAWER,
    });
  };
};
export const closeAccountDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_ACCOUNT_DIALOG,
    });
  };
};
export const openAccountDialog = () => {
  return dispatch => {
    dispatch({
      type: OPEN_ACCOUNT_DIALOG,
    });
  };
};
export const closeOnBookRequestDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_ONBOOKREQUEST_DIALOG,
    });
  };
};
export const openOnBookRequestDialog = (bookRequestDialogPage = "pending") => {
  return dispatch => {
    dispatch({
      type: OPEN_ONBOOKREQUEST_DIALOG,
      bookRequestDialogPage,
    });
  };
};
export const closeBookRequestDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_BOOKREQUEST_DIALOG,
    });
  };
};
export const openBookRequestDialog = request => {
  return dispatch => {
    dispatch({
      type: OPEN_BOOKREQUEST_DIALOG,
      request,
    });
  };
};

export const closeEditStatusDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_EDITSTATUS_DIALOG,
    });
  };
};
export const openEditStatusDialog = () => {
  return dispatch => {
    dispatch({
      type: OPEN_EDITSTATUS_DIALOG,
    });
  };
};

export const closeEditThemeDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_EDIT_THEME_DIALOG,
    });
  };
};
export const openEditThemeDialog = () => {
  return dispatch => {
    dispatch({
      type: OPEN_EDIT_THEME_DIALOG,
    });
  };
};
export const closeEditProfilePhotoDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_EDITPROFILEPHOTO_DIALOG,
    });
  };
};

export const openEditProfilePhotoDialog = () => {
  return dispatch => {
    dispatch({
      type: OPEN_EDITPROFILEPHOTO_DIALOG,
    });
  };
};
export const openEditProfileDialog = () => {
  return dispatch => {
    dispatch({
      type: OPEN_EDITPROFILE_DIALOG,
    });
  };
};

export const closeEditProfileDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_EDITPROFILE_DIALOG,
    });
  };
};

export const closeEditGalleryDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_EDITGALLERY_DIALOG,
    });
  };
};
export const openEditGalleryDialog = () => {
  return dispatch => {
    dispatch({
      type: OPEN_EDITGALLERY_DIALOG,
    });
  };
};

export const closeEditScheduleDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_EDITSCHEDULE_DIALOG,
    });
  };
};
export const openEditScheduleDialog = () => {
  return dispatch => {
    dispatch({
      type: OPEN_EDITSCHEDULE_DIALOG,
    });
  };
};
export const closeMyScheduleDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_MYSCHEDULE_DIALOG,
    });
  };
};
export const openMyScheduleDialog = () => {
  return dispatch => {
    dispatch({
      type: OPEN_MYSCHEDULE_DIALOG,
    });
  };
};

export const closeSendNotificationsDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_SEND_NOTIFICATIONS_DIALOG,
    });
  };
};
export const openSendNotificationsDialog = service => {
  return dispatch => {
    dispatch({
      type: OPEN_SEND_NOTIFICATIONS_DIALOG,
    });
  };
};

// export const closeAdminServiceDialog = () => {
//   return dispatch => {
//     dispatch({
//       type: CLOSE_ADMIN_SERVICE_DIALOG,
//     });
//   };
// };
// export const openAdminServiceDialog = id => {
//   return (dispatch, getState) => {
//     const service = getState().firestore.ordered.Services.find(
//       s => s.id === id
//     );
//     dispatch({
//       type: OPEN_ADMIN_SERVICE_DIALOG,
//       service,
//     });
//   };
// };
// export const closeServiceEntryDialog = () => {
//   return dispatch => {
//     dispatch({
//       type: CLOSE_SERVICE_ENTRY_DIALOG,
//     });
//   };
// };
// export const openServiceEntryDialog = service => {
//   return dispatch => {
//     dispatch({
//       type: OPEN_SERVICE_ENTRY_DIALOG,
//       service,
//     });
//   };
// };
export const closeServiceInfoDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_SERVICE_INFO_DIALOG,
    });
  };
};
export const openServiceInfoDialog = service => {
  return dispatch => {
    dispatch({
      type: OPEN_SERVICE_INFO_DIALOG,
      service,
    });
  };
};

export const openCreateAppDialog = () => {
  //delay(translate, 50);

  return dispatch => {
    dispatch({
      type: OPEN_CREATEAPP_DIALOG,
    });
  };
};
export const closeWarningDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_WARNING_DIALOG,
    });
  };
};
export const openWarningDialog = (title, message, actionText = "închide") => {
  return dispatch => {
    dispatch({
      type: OPEN_WARNING_DIALOG,
      title,
      message,
      actionText,
    });
  };
};
export const closeAuthDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_AUTH_DIALOG,
    });
  };
};
export const openAuthDialog = () => {
  return dispatch => {
    dispatch({
      type: OPEN_AUTH_DIALOG,
    });
  };
};

export const closeCreateAppDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_CREATEAPP_DIALOG,
    });
  };
};

// export const openEditAvatarDialog = () => {
//   //delay(translate, 50);
//   return dispatch => {
//     dispatch({
//       type: OPEN_EDITAVATAR_DIALOG,
//     });
//   };
// };
// export const closeEditAvatarDialog = () => {
//   return dispatch => {
//     dispatch({
//       type: CLOSE_EDITAVATAR_DIALOG,
//     });
//   };
// };

export const openSignUpDialog = () => {
  //delay(translate, 50);
  return dispatch => {
    dispatch({
      type: OPEN_SIGNUP_DIALOG,
    });
  };
};
export const closeSignUpDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_SIGNUP_DIALOG,
    });
  };
};
