import React from "react";

import {
  Button,
  Checkbox,
  CircularProgress,
  TextField,
} from "@material-ui/core";

import ErrorHelperText from "./AuthLayout_ErrorHelperText";

import {
  setFirstName,
  setLastName,
  sendUserDetailsToDB,
} from "../../../../store/actions/authActions";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useFirestore, useFirebase } from "react-redux-firebase";
import { setStep } from "../../../../store/actions/authActions";
import {
  openPrivacyDialog,
  openTermsDialog,
} from "../../../../store/actions/dialogsActions";

const useStyles = makeStyles(theme => ({
  root: {
    "-webkit-backface-visibility": "hidden",
    width: "100%",
    height: "100%",
    padding: 10,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    "&>*": {
      margin: "5px 0",
    },
    willChange: "transform",
  },
  title: {
    paddingBottom: 10,
    fontWeight: 400,
    fontSize: "1.1rem",
    borderBottom: "1px solid " + theme.palette.primary.main + "88",
  },
  text: {
    fontWeight: 200,
    fontSize: "0.8rem",
  },
  action: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    right: 0,
    display: "flex",

    flexDirection: "column",
  },
  actionsLine: {
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    "&>*": {
      "&:only-child": {
        marginLeft: "auto",
      },
    },
  },

  progressContainer: {
    position: "relative",
  },
  progress: {
    position: "absolute",
    top: "50%",
    right: "50%",
    marginTop: -12,
    marginRight: -12,
  },
  checkTerms: {
    display: "flex",
    fontWeight: 100,
    paddingBottom: 10,
    textAlign: "right",
  },
  clickableText: {
    fontWeight: 400,
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

export default ({ closeAfterLogin }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const firebase = useFirebase();

  const { firstName, lastName, loading, errorCode } = useSelector(
    store => store.auth
  );

  const { photoDataURL, isLoaded, isEmpty } = useSelector(
    store => store.firebase.profile
  );

  React.useEffect(() => {
    if (isLoaded && photoDataURL) {
      if (!!closeAfterLogin) {
        closeAfterLogin();
        dispatch(setStep(0));
      } else dispatch(setStep(3));
    } else if (isLoaded && isEmpty) {
      dispatch(setStep(0));
    }
  }, [isLoaded, isEmpty, photoDataURL, closeAfterLogin, dispatch]);

  const handleChangeFirstName = e =>
    dispatch(setFirstName(e.target.value.replace(/^\s+|\s+$/g, "")));
  const handleChangeLastName = e =>
    dispatch(setLastName(e.target.value.replace(/^\s+|\s+$/g, "")));

  const onSendClick = e => {
    e.preventDefault();
    dispatch(sendUserDetailsToDB({ firestore }));
  };

  const onDisconectClick = () => {
    firebase.auth().signOut();
  };

  const onTermsClick = () => dispatch(openTermsDialog());
  const onPrivacyClick = () => dispatch(openPrivacyDialog());

  const [accepted, setAccepted] = React.useState(false);
  const handleCheckboxChange = e => setAccepted(e.target.checked);

  return isLoaded ? (
    <form className={classes.root} onSubmit={onSendClick}>
      <div className={classes.title}>
        Bine ai venit{!!firstName && ", "}
        {firstName}!
      </div>
      <div className={classes.text}>
        Mai avem nevoie de câteva informatii pentru a finaliza procesul de
        inscriere:
      </div>
      <TextField
        color="primary"
        autoComplete="off"
        fullWidth
        value={lastName}
        required
        onChange={handleChangeLastName}
        label="Nume"
        variant="outlined"
        margin="dense"
      />

      <TextField
        color="primary"
        autoComplete="off"
        fullWidth
        value={firstName}
        required
        onChange={handleChangeFirstName}
        label="Prenume"
        variant="outlined"
        margin="dense"
        helperText={errorCode && <ErrorHelperText errorCode={errorCode} />}
      />

      <div className={classes.checkTerms}>
        <div>
          Accept{" "}
          <span onClick={onTermsClick} className={classes.clickableText}>
            Termenii și condițiile
          </span>{" "}
          și{" "}
          <span onClick={onPrivacyClick} className={classes.clickableText}>
            Politica de confidențialitate
          </span>
        </div>
        <Checkbox
          checked={accepted}
          onChange={handleCheckboxChange}
          name="checkedB"
          color="primary"
        />
      </div>

      <div className={classes.action}>
        <div className={classes.actionsLine}>
          <Button disabled={loading} color="primary" onClick={onDisconectClick}>
            Anulează
          </Button>
          <div className={classes.progressContainer}>
            <Button
              disabled={loading || !accepted}
              variant="contained"
              color="primary"
              type="submit"
            >
              continuă
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.progress} />
            )}
          </div>
        </div>
      </div>
    </form>
  ) : (
    <CircularProgress size={40} className={classes.progress} />
  );
};
