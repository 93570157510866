import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
import "firebase/messaging";
import "firebase/database";

export const DEV = false;

const firebaseConfig = {
  apiKey: "AIzaSyCO3pbf6RFnpgfzHhMCiCpniYaSVFwNbNE",
  authDomain: "pizzeria-melvin.firebaseapp.com",
  databaseURL: "https://pizzeria-melvin.europe-west1.firebasedatabase.app/",
  // databaseURL: "https://pizzeria-melvin-default-rtdb.firebaseio.com",
  projectId: "pizzeria-melvin",
  storageBucket: "pizzeria-melvin.appspot.com",
  messagingSenderId: "690910623849",
  appId: "1:690910623849:web:55f64ff2a7495918f18af8",
};

// const firebaseConfigDev = {

// };

// const firebaseConfigProd = {

// };

// Initialize Firebase
try {
  //firebase.initializeApp(DEV ? firebaseConfigDev : firebaseConfigProd);
  firebase.initializeApp(firebaseConfig);

  firebase.auth().useDeviceLanguage();

  //Access data offline
  !DEV &&
    firebase.firestore().settings({
      cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
    });
  !DEV && firebase.firestore().enablePersistence({ synchronizeTabs: true });

  DEV &&
    console.log("Initialized Firebase", [
      "Firebase",
      "Firestore",
      "Auth",
      "Storage",
      "Functions",
      "Messaging",
      "RTDB",
    ]);
} catch (err) {
  DEV && console.log("Error Initializing Firebase");
}

export default firebase;
