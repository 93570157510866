import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const resources = {
  en: {
    translation: require("./lang/en.json"),
  },
  no: {
    translation: require("./lang/no.json"),
  },
};

i18n.use(initReactI18next).init({
  lng: "no",
  fallbackLng: "en", // use en if detected lng is not available
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});
