import React from "react";

// import { MyTextField as TextField } from "../../../styledComponents";
import { Button, CircularProgress, TextField } from "@material-ui/core";

import ErrorHelperText from "./AuthLayout_ErrorHelperText";
import { useFirebase } from "react-redux-firebase";

import {
  setPhoneNumber,
  requestVerificationCode,
  checkVerificationCode,
  setStep,
  setErrorCode,
  setCode,
} from "../../../../store/actions/authActions";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    "-webkit-backface-visibility": "hidden",
    width: "100%",
    height: "100%",
    padding: 10,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    "&>*": {
      margin: "5px 0",
    },
    willChange: "transform",
  },
  title: {
    paddingBottom: 10,
    fontWeight: 400,
    fontSize: "1.1rem",
    borderBottom: "1px solid " + theme.palette.primary.main + "88",
  },
  text: {
    fontWeight: 200,
    fontSize: "0.8rem",
  },
  action: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    right: 0,
    display: "flex",

    flexDirection: "column",
  },
  actionsLine: {
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    "&>*": {
      "&:only-child": {
        marginLeft: "auto",
      },
    },
  },

  progressContainer: {
    position: "relative",
  },
  progress: {
    position: "absolute",
    top: "50%",
    right: "50%",
    marginTop: -12,
    marginRight: -12,
  },
  resendButton: {
    margin: "0 10px",
    fontSize: "0.6rem",
    fontWeight: 200,
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const [counter, setCounter] = React.useState(20);

  const { code, loading, errorCode, phoneNumber, step } = useSelector(
    store => store.auth
  );

  React.useEffect(() => {
    let timer = null;
    if (step === 1 && counter !== 0 && !loading) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [step, counter, loading]);

  const handleChange = event => {
    dispatch(setCode(event.target.value));
    errorCode && dispatch(setErrorCode(null));
  };
  const onContinueClick = e => {
    e.preventDefault();
    dispatch(checkVerificationCode());
  };
  const onResendClick = () => {
    setCounter(20);
    dispatch(requestVerificationCode({ firebase }));
  };
  const onCancelClick = () => {
    dispatch(setPhoneNumber("+40"));
    dispatch(setStep(0));
  };

  return (
    <form className={classes.root} onSubmit={onContinueClick}>
      <div className={classes.title}>Confirmați numărul de telefon</div>
      <div className={classes.text}>
        Introduceți codul din 6 cifre pe care l-am trimis la numărul{" "}
        <b>{phoneNumber}</b>
      </div>
      <TextField
        autoComplete="off"
        type="tel"
        color="primary"
        value={code}
        fullWidth
        variant="outlined"
        margin="dense"
        label="Cod primit prin SMS"
        onChange={handleChange}
        name="code"
        helperText={errorCode && <ErrorHelperText {...{ errorCode }} />}
      />
      <div className={classes.action}>
        <div className={classes.actionsLine}>
          <Button
            disabled={loading}
            //variant="contained"
            color="primary"
            // id="sign-in-button"
            onClick={onCancelClick} //"this.onClick"
          >
            Anulează
          </Button>
          <div className={classes.progressContainer}>
            <Button
              disabled={loading || code.length < 6}
              variant="contained"
              color="primary"
              type="submit"
              //  onClick={onContinueClick} //"this.onClick"
            >
              continuă
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.progress} />
            )}
          </div>
        </div>
        <Button
          disabled={counter !== 0}
          className={classes.resendButton}
          onClick={onResendClick}
        >
          {counter === 0
            ? "Retrimiteți SMS"
            : `Retrimiteți codul în ${counter} secunde`}
        </Button>
      </div>
    </form>
  );
};
