import React from "react";

const STYLE = {
  fontWeight: 100,
  fontSize: ".7rem",
  transform: "scaleY(0.9)",
  lineHeight: 1.1,
  color: "red",
};

export default ({ errorCode }) => {
  const result = errorCode => {
    switch (errorCode) {
      case "auth/invalid-phone-number":
        return "EROARE - număr de telefon invalid, incercați din nou.";
      case "auth/invalid-verification-code":
        return "EROARE - cod invalid, incercați din nou.";
      case "auth/too-many-requests":
        return "EROARE - ați efectuat prea multe solicitări, reveniți mai târziu.";
      case "name/all-fields-are-mandatory":
        return "*Toate câmpurile sunt obligatorii";
      default:
        return "EROARE, <" + errorCode + ">";
    }
  };

  return <span style={STYLE}>{result(errorCode)}</span>;
};
