import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    overflow: "hidden",
    width: "100%",
    height: "100%",
    // border: "2px solid green",
    opacity: ({ isVisible }) => (isVisible ? 1 : 0),
    transition: "opacity .1s",
    transitionDelay: ({ isVisible }) => (isVisible ? ".2s" : "0s"),
  },
  container: {
    width: "100%",
    height: "100%",
    // border: "2px solid red",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // fontFamily: "Poppins",
    fontSize: 60,
    [theme.breakpoints.up(767)]: {
      fontSize: 120,
      letterSpacing: 15,
    },

    letterSpacing: 10,
    color: theme.palette.primary[800],
    textAlign: "center",
    fontWeight: 500,
  },
  small: {
    textAlign: "center",
    fontSize: 12,
    [theme.breakpoints.up(767)]: {
      fontSize: 16,
    },
    fontWeight: 200,
  },
  iconButton: {
    border: "1px solid " + theme.palette.primary[800],
    color: theme.palette.primary[800],
    margin: 20,
    [theme.breakpoints.up(767)]: {
      margin: 40,
    },
  },
}));

const LastPage = ({ i }) => {
  const [isVisible, setIsVisible] = useState(true);
  const index = useSelector(state => state.landing.index);
  const classes = useStyles({ isVisible });
  const { t } = useTranslation();

  useEffect(() => {
    setIsVisible(i === index);
  }, [i, index]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div>{t`lastPage.menu`}</div>
        <div className={classes.small}>{t`lastPage.discover`}</div>
        <IconButton component={Link} to="/menu" className={classes.iconButton}>
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default LastPage;
