import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useFirebase } from "react-redux-firebase";
import { IconButton } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles(theme => ({
  root: {
    //
  },
}));

const SignOutButton = () => {
  const classes = useStyles();
  const firebase = useFirebase();

  const signOut = () => {
    firebase.auth().signOut();
    // window.location.reload();
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={signOut}>
        <ExitToAppIcon />
      </IconButton>
    </div>
  );
};

export default SignOutButton;
