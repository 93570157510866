import { DEV } from "../../config/firebaseConfig";

export const CHANGE_PRIMARY_COLOR = "CHANGE_PRIMARY_COLOR";
export const CHANGE_DARKMODE = "CHANGE_DARKMODE";
export const SET_MENUPAGE_INDEX = "SET_PAGE";

export const setMenuPage = index => {
  DEV && console.log("setPage Action");
  return dispatch => {
    dispatch({
      type: SET_MENUPAGE_INDEX,
      index,
    });
  };
};

export const setMenuPageByTitle = title => {
  DEV && console.log("setPage Action");
  return (dispatch, getState) => {
    const menu = getState().firebase.ordered.menu;
    const index = menu.findIndex(p => p?.value?.title === title) || 0;
    dispatch({
      type: SET_MENUPAGE_INDEX,
      index,
    });
  };
};

export const changePrimaryColor = primaryColor => {
  return dispatch => {
    dispatch({
      type: CHANGE_PRIMARY_COLOR,
      primaryColor,
    });
  };
};
