import React from "react";

import { CircularProgress } from "@material-ui/core";
import { SVGLogoWithSize } from "../layouts/logo/Logo";

export default ({ size = 50, ...otherProps }) => {
  const style = React.useMemo(
    () => ({ margin: `50% calc(50% - ${size / 2}px)` }),
    [size]
  );
  return (
    <div style={style}>
      <CircularProgress size={size} {...otherProps} />
    </div>
  );
};
const centerStyled = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export const CenterLoading = ({ size = 50, ...otherProps }) => {
  return (
    <div style={centerStyled}>
      <CircularProgress size={size} {...otherProps} />
    </div>
  );
};

export const LogoLoading = ({ size = 50 }) => {
  React.useEffect(() => {
    //this shell shows app background instead of white screen on that split second during refresh
    document.querySelector("#shell").removeAttribute("class");
    document.querySelector("#shellX").removeAttribute("class");
  }, []);
  return (
    <div style={centerStyled}>
      <SVGLogoWithSize size={size} />
    </div>
  );
};
