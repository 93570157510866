import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
//Redux
import {
  store,
  rrfProps,
  Provider,
  ReactReduxFirebaseProvider,
} from "./store/store";
// import swConfig from "./config/swConfig";
import "./i18n/config";

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);

// if (
//   !false &&
//   !/iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase())
// ) {
//   serviceWorker.register(swConfig);
// } else {
// serviceWorker.unregister();
// }
