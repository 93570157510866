import { ButtonBase } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  active: {
    fontWeight: 800,
    color: theme.palette.primary[800],
  },
  orderBtn: {
    fontSize: 16,
  },
}));

const NavButtons = ({ showActive, closeMenu }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleOrderClick = () => {
    closeMenu && closeMenu();
    window.open("https://order.weorder.com/restaurant/24219", "_blank");
  };
  const handleTableBookingClick = () => {
    closeMenu && closeMenu();
    window.open(
      "https://book.easytablebooking.com/book/?id=8cbc1&lang=auto",
      "_blank"
    );
  };
  return (
    <>
      <ButtonBase
        activeClassName={showActive && classes.active}
        component={NavLink}
        to="/"
        isActive={(match, location) => {
          if (location.pathname === match.url) {
            return true;
          }
          return false;
        }}
        onClick={closeMenu}
      >
        {t`menu.home`}
      </ButtonBase>
      <ButtonBase
        activeClassName={showActive && classes.active}
        component={NavLink}
        to="/menu"
        onClick={closeMenu}
      >
        {t`menu.menu`}
      </ButtonBase>
      <ButtonBase className={classes.orderBtn} onClick={handleOrderClick}>
        {t`menu.order.0`}&nbsp;{t`menu.order.1`}
      </ButtonBase>
      <ButtonBase
        className={classes.orderBtn}
        onClick={handleTableBookingClick}
      >
        {t`menu.booking.0`}&nbsp;{t`menu.booking.1`}
      </ButtonBase>
      <ButtonBase
        activeClassName={showActive && classes.active}
        component={NavLink}
        to="/contact"
        onClick={closeMenu}
      >
        {t`menu.contact`}
      </ButtonBase>
      <ButtonBase
        activeClassName={showActive && classes.active}
        component={NavLink}
        to="/b2b"
        onClick={closeMenu}
      >
        {t`menu.b2b`}
      </ButtonBase>
    </>
  );
};

export default NavButtons;
