import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuProduct from "./MenuProduct";

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary[800],
    overflow: "hidden",
    width: "100%",
    height: "100%",
    opacity: ({ isVisible }) => (isVisible ? 1 : 0),
    transition: "opacity .1s",
    transitionDelay: ({ isVisible }) => (isVisible ? ".2s" : "0s"),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "column",
    padding: 50,
    [theme.breakpoints.up(767)]: {
      padding: 120,
      flexDirection: "row",
      justifyContent: "space-evenly",
    },
    position: "relative",
  },
  image: {
    backgroundImage: ({ img }) => `url('${img}')`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 350,
    height: 500,
    [theme.breakpoints.down(767)]: {
      width: "80%",
      // height: 300,
      height: "70%",
      position: "absolute",
      opacity: 0.2,
      // top: "10vh",
      top: 50,
      left: "50%",
      transform: "translate(-50%, 0%)",
    },
  },

  textArea: {
    padding: "40px 0",
    width: "100%",
    maxWidth: 400,
    maxHeight: "100%",
    overflow: "scroll",
    position: "relative",
  },
  type: {
    fontSize: 12,
    fontWeight: "bold",
    padding: "10px 0 5px 0",
    color: theme.palette.primary.main,
  },
  infoArea: {
    fontSize: 10,
    fontWeight: 200,
    width: "90%",
    textAlign: "center",
    paddingBottom: 40,
    [theme.breakpoints.up(767)]: {
      position: "absolute",
      bottom: 110,
      paddingBottom: 0,
    },
  },
}));

const InMenuPage = ({ i, index, data }) => {
  const [isVisible, setIsVisible] = useState(true);
  const classes = useStyles({ isVisible, img: data.image });

  useEffect(() => {
    setIsVisible(i === index);
  }, [i, index]);

  return (
    <div className={classes.root}>
      <div className={classes.image}></div>

      <div className={classes.textArea}>
        <h2>{data.title}</h2>
        {data.categories.map(category => {
          const products = data.products.filter(p => p.category === category);
          return (
            <React.Fragment key={category}>
              {category && <div className={classes.type}>{category}</div>}

              {products.map(product => (
                <MenuProduct product={product} key={product.name} />
              ))}
            </React.Fragment>
          );
        })}
      </div>

      {data.info && <i className={classes.infoArea}>{data.info}</i>}
    </div>
  );
};

export default InMenuPage;
