// import services from "./servicesReducer";
// import todos from "./todosReducer";
// import dialogs from "./dialogsReducer";
import app from "./appReducer";
import auth from "./authReducer";
// import admin from "./adminReducer";
// import createApp from "./createAppReducer";
import theme from "./themeReducer";
import notifications from "./notificationsReducer";
// import agenda from "./agendaReducer";
import landing from "./landingReducer";
// import menu from "./menuReducer";

//import { firebaseStateReducer as firebase } from "react-redux-firebase";
import { firebaseReducer as firebase } from "react-redux-firebase";
import { firestoreReducer as firestore } from "redux-firestore";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  firestore,
  firebase,
  // dialogs,
  app,
  // agenda,
  // admin,
  auth,
  // createApp,
  theme,
  notifications,
  landing,
  // menu,
});

export default rootReducer;
