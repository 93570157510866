import React, { Suspense, lazy } from "react";

import colors from "./config/colors";
import overrides from "./config/MuiOverrides";

import CssBaseline from "@material-ui/core/CssBaseline";

import MomentUtils from "@date-io/moment";

import { BrowserRouter } from "react-router-dom";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useSelector } from "react-redux";
import { LogoLoading } from "./components/layouts/LoadingLayout";
import { DEV } from "./config/firebaseConfig";
import useSafariScrollTouchFix from "./components/hooks/useSafariScrollTouchFix";
//import { loadStripe } from "@stripe/stripe-js";
//import { Elements } from "@stripe/react-stripe-js";

//import AllRoutes from "./components/AllRoutes";
import CloseIcon from "@material-ui/icons/Close";

import { IconButton } from "@material-ui/core";
import ScaleOnSmallScreens from "./components/ScaleOnSmallScreens";
import Melvin from "./components/_melvin/Melvin";

// const pk = DEV
//   ? "pk_test_51HFHhXDfh9c7Fs4wWtlihnPZMHFsTaiJVJ13MzUpEWoE7IKL7GYDlfiOovO45avsH1Z9XYLQhBaUCjSM1dl1QyyP00FZ6FdOK3"
//   : "pk_live_51HFHhXDfh9c7Fs4wYSGo7Ly22R4LxwdvHBoT9fzzvhRd7iQ7rTHPXXFKgvs0LZrRxcFXVBt6PZppcwnJcREYLwFc00esSr9YoH";

//const stripePromise = loadStripe(pk);

const CookiesConsentLayout = lazy(() =>
  import("./components/layouts/legal/CookiesConsentLayout")
);
const Notifier = lazy(() => import("./components/layouts/Notifier"));
const FCMessaging = lazy(() => import("./components/layouts/FCMessaging"));
const SandBox = lazy(() => import("./components/layouts/SandBox"));
const OrientationHandler = lazy(() =>
  import("./components/layouts/OrientationHandler")
);
const ForceUpdateOnNewVersion = lazy(() =>
  import("./components/layouts/ForceUpdateOnNewVersion")
);
// const Dialogs = lazy(() => import("./components/dialogs"));

export default () => {
  const primaryColor = useSelector(store => store.theme.primaryColor);
  const darkMode = useSelector(store => store.theme.darkMode);

  let theme = createMuiTheme({
    palette: {
      primary: {
        100: "#CBDBCB",
        main: "#4a5a3f",
        800: "#252c25",
        900: "#252c25",
      },
      secondary: colors.find(color => color.id === "pink").import,
      type: "light",
      appBackground: darkMode
        ? "#000000"
        : colors.find(color => color.id === primaryColor).import[50],
      appPaper: "#fffb",
    },
    overrides: overrides(
      darkMode,
      colors.find(color => color.id === primaryColor).import
    ),
  });

  useSafariScrollTouchFix();
  DEV && console.log("[Theme]", theme);

  // add action to all snackbars
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };
  const snackBarProviderAction = key => (
    <IconButton onClick={onClickDismiss(key)}>
      <CloseIcon />
    </IconButton>
  );

  return (
    <ThemeProvider theme={theme}>
      <ScaleOnSmallScreens>
        <SnackbarProvider
          maxSnack={3}
          dense
          ref={notistackRef}
          action={snackBarProviderAction}
        >
          <MuiPickersUtilsProvider utils={MomentUtils}>
            {/* <Elements stripe={stripePromise}> */}
            <CssBaseline />
            <Suspense fallback={<LogoLoading size={100} />}>
              <BrowserRouter>
                <Melvin />
              </BrowserRouter>
              <Suspense fallback={null}>
                {/* <Dialogs /> */}
                <ForceUpdateOnNewVersion />
                <OrientationHandler />
                {DEV && <SandBox />}
                <Notifier />
                <FCMessaging />
                <CookiesConsentLayout />
              </Suspense>
            </Suspense>
            {/* </Elements> */}
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </ScaleOnSmallScreens>
    </ThemeProvider>
  );
};
