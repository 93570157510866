import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonBase } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Instagram, Facebook } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {
    //padding: 30,
    display: "grid",
    gridAutoFlow: "column",
    gridGap: 20,
  },
  primary: {
    color: theme.palette.primary[800],
  },
}));

const SocialMediaLinks = ({ color, fontSize = "large" }) => {
  const classes = useStyles();
  return (
    <div
      className={[classes.root, color === "primary" && classes.primary].join(
        " "
      )}
    >
      <ButtonBase
        component={Link}
        to={{ pathname: "https://www.facebook.com/pizzeriamelvin" }}
        target="_blank"
      >
        <Facebook fontSize={fontSize} />
      </ButtonBase>
      <ButtonBase
        component={Link}
        to={{ pathname: "https://www.instagram.com/pizzeriamelvin/" }}
        target="_blank"
      >
        <Instagram fontSize={fontSize} />
      </ButtonBase>
    </div>
  );
};

export default SocialMediaLinks;
