import { useEffect, useState } from "react";

export default () => {
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    setIsSafari(checkForSafari());
  }, []);

  useEffect(() => {
    const fixScrollingIssue = () => {
      document.body.scrollTop = 0;
    };

    isSafari && document.addEventListener("touchmove", fixScrollingIssue);

    return () => {
      document.removeEventListener("touchmove", fixScrollingIssue);
    };
  }, [isSafari]);

  //return "Fix for -webkit-overflow-scrolling touch " + isSafari;
};

function checkForSafari() {
  const ua = window.navigator.userAgent;
  const webkit = !!ua.match(/WebKit/i);
  const isIPad = !!ua.match(/iPad/i);
  const isIPhone = !!ua.match(/iPhone/i);
  const isIOS = isIPad || isIPhone;
  const isSafari = isIOS && webkit && !ua.match(/CriOS/i);

  return isSafari;
}
