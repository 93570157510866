import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SocialMediaLinks from "../layouts/SocialMediaLinks";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down(767)]: {
      display: "none",
    },
    zIndex: 1001,
    position: "absolute",
    bottom: 0,
    right: 90,
    height: 90,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const SocialMedia = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SocialMediaLinks color="primary" />
    </div>
  );
};

export default SocialMedia;
