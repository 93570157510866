import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SignOutButton from "../elements/adminLayouts/SignOutButton";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    overflow: "hidden",
  },
  container: {
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Admin = () => {
  const classes = useStyles();

  const displayName = useSelector(state => state.firebase.profile.displayName);

  if (!displayName) {
    return <Redirect to="/auth" />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <SignOutButton />
      </div>
    </div>
  );
};

export default Admin;
