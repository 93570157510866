import { DEV } from "../../config/firebaseConfig";

export const SET_STEP = "SET_STEP";
export const SET_PHONE_NUMBER = "SET_PHONE_NUMBER";
export const SET_CODE = "SET_CODE";
export const SET_LOADING = "SET_LOADING";
export const SET_CONFIRMATION_RESULT = "SET_CONFIRMATION_RESULT";
export const SET_ERROR_CODE = "SET_ERROR_CODE";
export const SET_RECAPTCHA_VERIFIER = "SET_RECAPTCHA_VERIFIER";
export const SET_FIRSTNAME = "SET_FIRSTNAME";
export const SET_LASTNAME = "SET_LASTNAME";

export const setStep = step => {
  return dispatch => {
    dispatch({
      type: SET_STEP,
      step,
    });
  };
};
export const setPhoneNumber = phoneNumber => {
  return dispatch => {
    dispatch({
      type: SET_PHONE_NUMBER,
      phoneNumber,
    });
  };
};
export const setCode = code => {
  return dispatch => {
    dispatch({
      type: SET_CODE,
      code,
    });
  };
};
export const setFirstName = firstName => {
  return dispatch => {
    dispatch({
      type: SET_FIRSTNAME,
      firstName,
    });
  };
};
export const setLastName = lastName => {
  return dispatch => {
    dispatch({
      type: SET_LASTNAME,
      lastName,
    });
  };
};
export const setLoading = loading => {
  return dispatch => {
    dispatch({
      type: SET_LOADING,
      loading,
    });
  };
};
export const setConfirmationResult = confirmationResult => {
  return dispatch => {
    dispatch({
      type: SET_CONFIRMATION_RESULT,
      confirmationResult,
    });
  };
};
export const setRecaptchaVerifier = recaptchaVerifier => {
  return (dispatch, getState) => {
    (!getState().auth.recaptchaVerifier || !recaptchaVerifier) &&
      dispatch({
        type: SET_RECAPTCHA_VERIFIER,
        recaptchaVerifier,
      });
  };
};
export const setErrorCode = (errorCode = null) => {
  return (dispatch, getState) => {
    errorCode !== getState().auth.errorCode &&
      dispatch({
        type: SET_ERROR_CODE,
        errorCode,
      });
  };
};
export const requestVerificationCode = ({ firebase }) => {
  DEV && console.log("requestVerificationCode");
  return (dispatch, getState) => {
    const { phoneNumber, recaptchaVerifier } = getState().auth;
    dispatch(setLoading(true));
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
      .then(function (confirmationResult) {
        DEV && console.log("ok");
        dispatch(setConfirmationResult(confirmationResult));
        dispatch(setLoading(false));
        dispatch(setCode(""));
        dispatch(setErrorCode(null));
        dispatch(setStep(1));
      })
      .catch(function (error) {
        DEV && console.log("error", error);
        dispatch(setErrorCode(error.code));
        dispatch(setLoading(false));
        dispatch(setPhoneNumber("+40"));
      });
  };
};
export const checkVerificationCode = () => {
  DEV && console.log("checkVerificationCode");
  return (dispatch, getState) => {
    const { code, confirmationResult } = getState().auth;
    dispatch(setLoading(true));
    confirmationResult
      .confirm(code)
      .then(function (result) {
        DEV && console.log("Logged in", result);
        dispatch(setStep(2));
        dispatch(setLoading(false));
        dispatch(setPhoneNumber("+40"));
        dispatch(setLoading(false));
        dispatch(setCode(""));
        dispatch(setRecaptchaVerifier(null));
        dispatch(setConfirmationResult(null));
      })
      .catch(function (error) {
        DEV && console.log(error);
        dispatch(setErrorCode(error.code));
        dispatch(setLoading(false));
        dispatch(setCode(""));
      });
  };
};

export const sendUserDetailsToDB = ({ firestore }) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const { firstName, lastName } = getState().auth;
    const { uid, createdAt, phoneNumber } = getState().firebase.auth;
    if (firstName !== "" && lastName !== "" && uid) {
      //Send to Firestore
      const photoDataURL = generateAvatar(firstName, lastName);

      const userDetails = {
        uid,
        createdAt,
        photoDataURL,
        phoneNumber,
        lastName,
        firstName,
        displayName: lastName + " " + firstName,
      };
      firestore
        .collection("Users")
        .doc(uid)
        .set(userDetails)
        .then(() => {
          DEV && console.log("data was sent..");
        })
        .catch(err => {
          DEV && console.log(err);
        });
      dispatch(setErrorCode(false));
      //dispatch(setStep(0));
      dispatch(setLoading(false));
      //dispatch(closeAuthDialog());
    } else {
      uid
        ? dispatch(setErrorCode("name/all-fields-are-mandatory"))
        : dispatch(setErrorCode("name/unautorized-attempt"));
      dispatch(setLoading(false));
    }
  };
};

export const sendFacebookUserDetailsToDB = ({ accessToken, firestore }) => {
  return async (dispatch, getState) => {
    const {
      displayName,
      phoneNumber,
      email,
      createdAt,
      photoURL,
      uid,
    } = getState().firebase.auth;
    const names = displayName && displayName.split(" ");
    const firstName = names[0];
    const lastName = names[names.length - 1];

    const profilePhotoUrl = `${photoURL}?height=100&access_token=${accessToken}`;
    const photoDataURL = await convertToDataURL(profilePhotoUrl, 100, 100);

    //Send to Firestore
    const userDetails = {
      uid,
      createdAt,
      photoDataURL,
      phoneNumber,
      email,
      lastName,
      firstName,
      displayName,
    };

    firestore
      .collection("Users")
      .doc(uid)
      .set(userDetails)
      .then(() => {
        DEV && console.log("data was sent..");
      })
      .catch(err => {
        DEV && console.log(err);
      });
  };
};
const convertToDataURL = (url, width, height) =>
  new Promise(resolve => {
    var sourceImage = new Image();
    sourceImage.crossOrigin = "anonymous";
    sourceImage.onload = function () {
      // Create a canvas with the desired dimensions
      var canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      // Scale and draw the source image to the canvas
      canvas.getContext("2d").drawImage(sourceImage, 0, 0, width, height);
      // Convert the canvas to a data URL in PNG format
      resolve(canvas.toDataURL());
      //resolve(canvas.toDataURL("image/jpeg", 0.5));
    };
    sourceImage.src = url;
  });
const generateAvatar = (firstName, lastName) => {
  var initials = firstName[0].toUpperCase() + lastName[0].toUpperCase();
  var canvas = document.createElement("canvas");
  var radius = 30;
  var margin = 0;
  canvas.width = radius * 2 + margin * 2;
  canvas.height = radius * 2 + margin * 2;

  // Get the drawing context
  var ctx = canvas.getContext("2d");
  ctx.beginPath();
  ctx.arc(radius + margin, radius + margin, radius, 0, 2 * Math.PI, false);
  ctx.closePath();
  ctx.fillStyle = "#7776";
  ctx.fill();
  ctx.fillStyle = "white";
  ctx.font = "30px Roboto";
  ctx.textAlign = "center";
  ctx.fillText(initials, radius + margin, (radius * 4) / 3 + margin + 1);
  return canvas.toDataURL();
};
