import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import MenuNavButton from "./MenuNavButton";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%)",
    zIndex: 999,
    overflow: "scroll",
    display: "flex",
    gap: 10,
    maxWidth: "90%",
    padding: "0 30px",
    top: 130,
    [theme.breakpoints.down(767)]: {
      top: 100,
      maxWidth: "70%",
      "-webkit-mask-image":
        "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%)",
      "-webkit-mask-size": "100% 100%",
      "-webkit-mask-repeat": "no-repeat",
      "-webkit-mask-position": "left top, left bottom",
    },
  },
}));

const MenuNav = () => {
  const [titles, setTitles] = useState([]);

  const classes = useStyles();
  const myRef = useRef(null);

  const menu = useSelector(store => store.firebase.data.menu);

  useEffect(() => {
    if (menu) {
      setTitles([...new Set(menu.map(m => m.title))]);
      setTimeout(() => (myRef.current.scrollLeft += 30), 1);
    }
  }, [menu, myRef]);

  return (
    <div ref={myRef} className={classes.root}>
      {titles.map(title => (
        <MenuNavButton key={title} title={title} />
      ))}
    </div>
  );
};

export default MenuNav;
