import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Typography } from "@material-ui/core";

//import Sizer from "./Sizer";
import { useFirebase } from "react-redux-firebase";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    //border: "2px solid " + theme.palette.primary.main,
    padding: 20,
    borderRadius: 5,
    // boxShadow: theme.shadows[3],
    // background: "#fff",
    // maxHeight: 400,
    // overflow: "scroll",
    display: "grid",
    gridGap: 10,
    width: 600,
    maxWidth: "80%",

    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      maxWidth: 600,
    },
    paddingBottom: 100,
  },
  textfieldsArea: {
    // border: "2px solid green",
    display: "flex",
    flexDirection: "column",
  },
  sizersArea: {
    // border: "2px solid red",
  },
  container: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: 40,
  },
  wrapper: {
    position: "relative",
    [theme.breakpoints.up(767)]: {
      marginTop: 30,
    },
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  description: {
    color: theme.palette.primary[900],
  },
}));

const initialState = {
  name: "",
  email: "",
  details: "",
};

const reducer = (state, action) => {
  return { ...state, [action.type]: action.value };
};

const B2bForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const firebase = useFirebase();
  const history = useHistory();
  const functions = firebase.app().functions("europe-west1");
  const sendB2BRequest = functions.httpsCallable("sendB2BRequest");

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [requestSent, setRequestSent] = React.useState(false);

  React.useEffect(() => {
    setErrorMessage("");
  }, [state]);

  React.useEffect(() => {
    if (requestSent)
      setTimeout(() => {
        history.push("/");
      }, 2000);
  }, [requestSent, history]);

  //   const handleChangeDate = type => value => {
  //     dispatch({ type, value });
  //     dispatch({
  //       type: type.replace("Moment", "Date"),
  //       value: value.format("DD.MM.YYYY"),
  //     });
  //   };

  //   const handleChange = type => value => {
  //     dispatch({ type, value });
  //   };

  const handleChangeEvent = type => event => {
    const value = event.target.value;
    // if (type === "details") doScrollIntoView("submit-button");
    dispatch({ type, value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    // console.log(`handleSubmit`);
    const { fromMoment, toMoment, ...data } = state;
    const response = await sendB2BRequest(data);

    if (response.data.status === "success") {
      setRequestSent(true);
    } else if (response.data.status === "flood") {
      setErrorMessage(t`b2b.floodErrorMessage`);
    }
    // console.log(`response`, response);
    setLoading(false);
  };
  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <div>
        {/* <h3 className={classes.title}>{t`b2b.title`}</h3> */}
        <p className={classes.description}>{t`b2b.description`}</p>
      </div>

      <div className={classes.textfieldsArea}>
        <TextField
          type="name"
          value={state.name}
          label={t`b2b.name`}
          size="small"
          required
          onChange={handleChangeEvent("name")}
        />
        <TextField
          type="tel"
          value={state.email}
          label={t`b2b.email`}
          size="small"
          required
          onChange={handleChangeEvent("email")}
        />
      </div>
      <TextField
        type="details"
        value={state.details}
        label={t`b2b.otherDetails`}
        size="small"
        onChange={handleChangeEvent("details")}
        //multiline
        //rows={2}
      />

      <Typography variant="caption" color="error">
        {errorMessage}
      </Typography>

      <div className={classes.wrapper}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={requestSent || loading}
          fullWidth
          id="submit-button"
        >
          {!requestSent ? t`b2b.sendRequest` : t`b2b.requestSent`}
        </Button>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </form>
  );
};

export default B2bForm;

// const doScrollIntoView = id => {
//   const elem = document.getElementById(id);
//   elem.scrollIntoView({ behavior: "smooth", block: "center" });
// };
