import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { onMenuClick } from "../../../../store/actions/landingActions";
import { Link } from "react-router-dom";
import { ButtonBase } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  homeButton: {
    zIndex: 1001,
    position: "absolute",
    top: "0",
    right: "50%",
    transform: "translate(50% , 0)",
    [theme.breakpoints.up(767)]: {
      top: "50%",
      left: 0,
      transform: "translate(0, -50%)",
      width: 130,
      height: 90,
    },
    background: theme.palette.primary.main,
    color: "white",

    width: 100,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: 50,
    height: 50,
    [theme.breakpoints.up(767)]: {
      width: 60,
      height: 60,
    },
  },
}));

const HomeButton = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [logo, setLogo] = useState("\\svg\\melvin-logo-white.svg");

  const handleMouseEnter = e => {
    setLogo("\\svg\\melvin-logo-notext.svg");
  };
  const handleMouseLeave = e => {
    setLogo("\\svg\\melvin-logo-white.svg");
  };

  const closeMenu = () => dispatch(onMenuClick(false));
  return (
    <ButtonBase
      component={Link}
      className={classes.homeButton}
      to="/"
      onClick={closeMenu}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img className={classes.logo} loading="lazy" src={logo} alt="ref" />
    </ButtonBase>
  );
};

export default HomeButton;
