//import useMediaQuery from "@material-ui/core/useMediaQuery";

const scale = x => {
  return {
    transform: `scale(${x})`,
    transformOrigin: "0 0",
    width: `${100 / x}%`,
    height: `${100 / x}%`,
    left: 0,
    top: 0,
  };
};

export default (darkMode, color) => {
  //const fullscreen = useMediaQuery("(max-width:600px)");
  return {
    MuiDialog: {
      root: {
        "@media (max-width:329.95px)": {
          ...scale(0.8),
        },
        "@media (max-width:269.95px)": {
          ...scale(0.7),
        },
        "@media (max-width:239.95px)": {
          ...scale(0.6),
        },
        "@media (max-width:199.95px)": {
          ...scale(0.4),
        },
      },
      // paper: {
      //   background: "#ffffff", //darkMode ? "#222" : color[50],
      // },
      // paperWidthXs: {
      //   height: 700,
      //   maxWidth: 350,
      //   overflow: "hidden",
      // },
      // paperScrollPaper: {
      //   //
      //   maxHeight: "calc(100% - 16px)",
      // },
    },
    // MuiDialogTitle: {
    //   root: {
    //     padding: 0,
    //     display: "flex",
    //     alignItems: "center",
    //     minHeight: 47,
    //     userSelect: "none",
    //   },
    // },
    // MuiTab: {
    //   root: {
    //     minHeight: 32,
    //     fontSize: "0.8rem",
    //   },
    // },
    // MuiTabs: {
    //   root: {
    //     minHeight: 32,
    //   },
    // },
  };
};
