import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const scale = x => {
  return {
    transform: `scale(${x})`,
    transformOrigin: "0 0",
    width: `${100 / x}%`,
    height: `${100 / x}%`,
    left: 0,
    top: 0,
  };
};
const useStyles = makeStyles(theme => ({
  root: {
    // border: "2px solid red",
    position: "fixed",
    width: "100%",
    height: "100%",
  },
  container: {
    // border: "2px solid green",
    position: "relative",
    width: "100%",
    height: "100%",
    //fix for scaled small screens
    // [theme.breakpoints.down(380)]: {
    //   position: "absolute",
    //   ...scale(0.9)
    // },
    [theme.breakpoints.down(330)]: {
      position: "absolute",
      ...scale(0.8),
    },
    [theme.breakpoints.down(270)]: {
      position: "absolute",
      ...scale(0.7),
    },
    [theme.breakpoints.down(240)]: {
      position: "absolute",
      ...scale(0.6),
    },
    [theme.breakpoints.down(200)]: {
      position: "absolute",
      ...scale(0.4),
    },
  },
}));

const ScaleOnSmallScreens = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>{children}</div>
    </div>
  );
};

export default ScaleOnSmallScreens;
