import React from "react";
import { useFirebaseConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";

import SocialMediaLinks from "../elements/layouts/SocialMediaLinks";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    overflow: "hidden",
  },
  container: {
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: 2,
  },
  logo: {
    margin: 20,
    height: 100,
    // [theme.breakpoints.up(767)]: {
    //   height: 250,
    // },
  },
  social: {
    padding: 30,
    display: "grid",
    gridAutoFlow: "column",
    gridGap: 20,
  },
  contact: {
    fontSize: 18,
    textAlign: "center",
    color: theme.palette.primary[900],
    padding: 20,
  },
  copyright: {
    [theme.breakpoints.up(767)]: {
      display: "none",
    },
    fontSize: 12,
    fontFamily: "courier",
  },
  socialMediaLinks: {
    [theme.breakpoints.up(767)]: {
      display: "none",
    },
  },
  iconTextContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 50,
    alignItems: "center ",
    "&>*": {
      lineHeight: 3,
    },
  },
}));

const Contact = () => {
  const classes = useStyles();

  const { contact, schedule } = useSelector(store => store.firebase.data);

  useFirebaseConnect({
    path: `/contact`,
    storeAs: "contact",
  });

  useFirebaseConnect({
    path: `/schedule`,
    storeAs: "schedule",
  });

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.logo}>
          <img
            loading="lazy"
            src="\svg\melvin-logo.svg"
            alt="ref"
            height="100"
            width="100"
          />
        </div>
        <div className={classes.contact}>
          {schedule &&
            schedule.map((obj, index) => {
              const [day, time] = Object.entries(obj)[0];
              return (
                <React.Fragment key={index}>
                  <b>{day}</b>
                  <div>{time}</div>
                </React.Fragment>
              );
            })}
          <br />
          <div className={classes.iconTextContainer}>
            <LocationOnIcon />
            {contact?.address ? (
              <span>Parkveien 4F, 1405 Langhus</span>
            ) : (
              <span>...</span>
            )}
          </div>
          <br />
          <div className={classes.iconTextContainer}>
            <AlternateEmailIcon />
            {contact?.email ? <div>{contact.email}</div> : <div>...</div>}
          </div>
        </div>
        <div className={classes.socialMediaLinks}>
          <SocialMediaLinks color="primary" />
        </div>
        <code className={classes.copyright}>
          <br />© {new Date().getFullYear()} | Pizzeria Melvin
        </code>
      </div>
    </div>
  );
};

export default Contact;
