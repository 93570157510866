import React, { useEffect } from "react";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import { makeStyles } from "@material-ui/core/styles";
import InMenuPage from "../elements/Pages/InMenuPage";
import { useSelector, useDispatch } from "react-redux";
import { setMenuPage } from "../../../store/actions/appActions";
import MenuNav from "../elements/menuNav/MenuNav";

const useStyles = makeStyles(theme => ({
  root: {
    //fontFamily: "Poppins" ,
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    overflow: "hidden",
    // background: "lightgrey",
    // border: "3px solid red",
  },
  container: {
    height: "100%",
    overflow: "visible",
    transition: `transform 0.5s`,
    transform: ({ index }) => `translateY(${-100 * index}%)`,
  },
  pageNumber: {
    color: "#3335",
    fontWeight: 800,
    fontSize: 20,
    position: "absolute",
    right: 50,
    bottom: 50,
    [theme.breakpoints.up(767)]: {
      fontSize: 30,
      right: 120,
      bottom: 120,
    },
  },
}));

const containerStyle = {
  width: "calc(100% + 4px)",
  height: "calc(100% + 4px)",
  margin: -2,
  padding: 2,
};

const Menu = () => {
  const dispatch = useDispatch();

  const menu = useSelector(store => store.firebase.ordered.menu);
  const index = useSelector(store => store.app.menuIndex);

  const classes = useStyles({ index });

  const upHandler = () => {
    dispatch(setMenuPage(Math.max(0, index - 1)));
  };

  const downHandler = () => {
    dispatch(setMenuPage(Math.min(index + 1, menu.length - 1 || 0)));
  };

  useEffect(() => {
    return () => {
      dispatch(setMenuPage(0));
    };
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <MenuNav />
      <ReactScrollWheelHandler
        {...{ upHandler, downHandler }}
        style={containerStyle}
      >
        <div className={classes.container}>
          {menu &&
            menu.map((m, i) => (
              <InMenuPage key={m.key} i={i} index={index} data={m.value} />
            ))}
        </div>
      </ReactScrollWheelHandler>
      {/* {menu && (
        <div className={classes.pageNumber}>
          {index + 1}/{menu.length}
        </div>
      )} */}
    </div>
  );
};

export default Menu;
