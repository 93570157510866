import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonBase } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    overflow: "hidden",
  },
  container: {
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    margin: "30px 0",
    minWidth: "70vw",
    lineHeight: 1,
    color: theme.palette.primary[800],
    letterSpacing: 10,
    fontSize: 25,
    [theme.breakpoints.up(767)]: {
      letterSpacing: 15,
      fontSize: 60,
    },
    // borderBottom: "5px solid transparent",
    // transition: "border .5s",
    "&:hover": {
      // transition: "border .1s",
      // borderBottom: "5px solid " + theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

const services = [
  {
    name: "Uber Eats",
    pathname: "https://google.com",
  },
  {
    name: "Food Panda",
    pathname: "https://google.com",
  },
  {
    name: "Glovo",
    pathname: "https://google.com",
  },
];

const Order = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {services.map((s, key) => (
          <ButtonBase
            className={classes.button}
            key={key}
            component={Link}
            to={{ pathname: s.pathname }}
            target="_blank"
          >
            {s.name}
          </ButtonBase>
        ))}
      </div>
    </div>
  );
};

export default Order;
