import React from "react";
import { SvgIcon } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  lines: {
    willChange: "transform",
    "-webkit-backface-visibility": "hidden",
    transition: "transform 500ms ",
    transformOrigin: "center",
  },
  one: {
    transform: "rotate(45deg) translate(3.5px,3.5px)",
  },
  two: {
    transform: "rotate(-45deg) translate(3.5px,-3.5px)",
  },
  three: {
    transform: "scaleX(0)",
    //opacity: 0,
  },
  root: {
    //transform: "rotate(90deg)",
  },
}));

export default props => {
  const open = props.open;
  const classes = useStyles();

  return (
    <SvgIcon {...props} className={classes.root}>
      <path
        className={[classes.lines, !open && classes.one].join(" ")}
        d="M5.3999 16.7L16.7136 5.38624L18.5521 7.22472L7.23838 18.5384L5.3999 16.7Z"
      />
      <path
        className={[classes.lines, !open && classes.two].join(" ")}
        d="M7.23828 5.3999L18.552 16.7136L16.7135 18.5521L5.3998 7.23838L7.23828 5.3999Z"
      />
      <path
        className={[classes.lines, open && classes.three].join(" ")}
        d="M4 10.7H20V13.3H4V10.7Z"
      />
    </SvgIcon>
  );
};
