import React, { useCallback, useEffect } from "react";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { setIndex } from "../../../store/actions/landingActions";
import FirstPage from "../elements/Pages/FirstPage";
import MiddlePage from "../elements/Pages/MiddlePage";
import LastPage from "../elements/Pages/LastPage";
import { useTranslation } from "react-i18next";
import { setMenuPageByTitle } from "../../../store/actions/appActions";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    //fontFamily: "Poppins",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    overflow: "hidden",
    // background: "lightgrey",
  },
  container: {
    height: "100%",
    overflow: "visible",
    transition: `transform 0.5s`,
    transform: ({ index }) => `translateY(${-100 * index}%)`,
  },
}));

const containerStyle = {
  width: "calc(100% + 4px)",
  height: "calc(100% + 4px)",
  margin: -2,
  padding: 2,
};

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const index = useSelector(store => store.landing.index);
  const classes = useStyles({ index });

  const upHandler = () => dispatch(setIndex(index - 1));
  const downHandler = () => dispatch(setIndex(index + 1));

  useEffect(() => {
    return () => {
      dispatch(setIndex(0));
    };
  }, [dispatch]);

  const onPizzaClick = useCallback(() => {
    dispatch(setMenuPageByTitle("Pizza"));
    history.push("/menu");
  }, [dispatch, history]);

  const onPastaClick = useCallback(() => {
    dispatch(setMenuPageByTitle("Pasta"));
    history.push("/menu");
  }, [dispatch, history]);

  const onDessertClick = useCallback(() => {
    dispatch(setMenuPageByTitle("Dessert"));
    history.push("/menu");
  }, [dispatch, history]);

  return (
    <div className={classes.root}>
      <ReactScrollWheelHandler
        {...{ upHandler, downHandler }}
        style={containerStyle}
      >
        <div className={classes.container}>
          <FirstPage i={0} />
          <MiddlePage
            i={1}
            img="/img/pizza.png"
            title={t(`pages.${1}`)}
            onClick={onPizzaClick}
          />
          <MiddlePage
            i={2}
            img="/img/pasta.png"
            title={t(`pages.${2}`)}
            onClick={onPastaClick}
          />
          <MiddlePage
            i={3}
            img="/img/cake.png"
            title={t(`pages.${3}`)}
            onClick={onDessertClick}
          />
          <LastPage i={4} />
        </div>
      </ReactScrollWheelHandler>
    </div>
  );
};

export default Home;
