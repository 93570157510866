import { DEV } from "../../config/firebaseConfig";

import {
  SET_STEP,
  SET_PHONE_NUMBER,
  SET_LOADING,
  SET_CONFIRMATION_RESULT,
  SET_ERROR_CODE,
  SET_CODE,
  SET_RECAPTCHA_VERIFIER,
  SET_FIRSTNAME,
  SET_LASTNAME,
} from "./../actions/authActions";

const initState = {
  step: 0,
  phoneNumber: "+4",
  firstName: "",
  lastName: "",
  code: "",
  loading: false,
  recaptchaVerifier: null,
  confirmationResult: null,
  errorCode: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case SET_STEP:
      DEV && console.log(SET_STEP, action.step);
      return {
        ...state,
        step: action.step,
      };
    case SET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.phoneNumber,
      };
    case SET_CODE:
      return {
        ...state,
        code: action.code,
      };
    case SET_FIRSTNAME:
      return {
        ...state,
        firstName: action.firstName,
      };
    case SET_LASTNAME:
      return {
        ...state,
        lastName: action.lastName,
      };
    case SET_LOADING:
      DEV && console.log(SET_LOADING, action.loading);
      return {
        ...state,
        loading: action.loading,
      };
    case SET_CONFIRMATION_RESULT:
      DEV && console.log(SET_CONFIRMATION_RESULT);
      return {
        ...state,
        confirmationResult: action.confirmationResult,
      };
    case SET_RECAPTCHA_VERIFIER:
      DEV && console.log(SET_RECAPTCHA_VERIFIER);
      return {
        ...state,
        recaptchaVerifier: action.recaptchaVerifier,
      };
    case SET_ERROR_CODE:
      DEV && console.log(SET_ERROR_CODE);
      return {
        ...state,
        errorCode: action.errorCode,
      };
    default:
      return state;
  }
};
