import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { ButtonBase } from "@material-ui/core";
import { setMenuPageByTitle } from "../../../../store/actions/appActions";

const useStyles = makeStyles(theme => ({
  root: {
    background: "none",
    color: theme.palette.primary.main,
    border: "none",
    margin: "0 5px",
    padding: 5,
    fontSize: 14,
    textTransform: "uppercase",
    fontWeight: "bold",
    borderRadius: 5,
    [theme.breakpoints.up(767)]: {
      margin: "0 20px",
    },
  },
}));

const MenuNavButton = ({ title }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClick = () => {
    dispatch(setMenuPageByTitle(title));
  };

  return (
    <ButtonBase onClick={handleClick} className={classes.root}>
      {title}
    </ButtonBase>
  );
};

export default MenuNavButton;
