import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";
//import firebase from "../config/fbConfig";
import firebase from "../config/firebaseConfig";
import { createFirestoreInstance } from "redux-firestore";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
export { Provider, ReactReduxFirebaseProvider };

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// store
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

// react-redux-firebase options
const rrfConfig = {
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  userProfile: "Users", // firebase root where user profiles are stored
  //enableLogging: false // enable/disable Firebase's database logging
  enableClaims: true, // Get custom claims along with the profile
};

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};
