import { DEV } from "../../config/firebaseConfig";

import { CHANGE_PRIMARY_COLOR, CHANGE_DARKMODE } from "./../actions/appActions";

const initState = {
  darkMode: true,
  primaryColor: "blue",
};

export default (state = initState, action) => {
  switch (action.type) {
    case CHANGE_PRIMARY_COLOR:
      DEV && console.log(CHANGE_PRIMARY_COLOR);
      return {
        ...state,
        primaryColor: action.primaryColor,
      };
    case CHANGE_DARKMODE:
      DEV && console.log(CHANGE_DARKMODE);
      return {
        ...state,
        darkMode: action.value,
      };
    default:
      return state;
  }
};
