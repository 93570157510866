//import { DEV } from "../../config/firebaseConfig";

export const SET_INDEX = "SET_INDEX";
export const ON_MENU_CLICK = "ON_MENU_CLICK";
// export const SHOW_ACTION_BAR = "SHOW_ACTION_BAR";
export const HERO_ACTION_BUTON_VISIBLE = "HERO_ACTION_BUTON_VISIBLE";
export const FOOTER_ACTION_BUTON_VISIBLE = "FOOTER_ACTION_BUTON_VISIBLE";
export const SCARCITY_ACTION_BUTON_VISIBLE = "SCARCITY_ACTION_BUTON_VISIBLE";

export const setScarcityActionButtonVisible = visible => {
  return (dispatch, getState) => {
    if (visible === getState().landing.isScarcityActionButtonVisible) return;
    dispatch({
      type: SCARCITY_ACTION_BUTON_VISIBLE,
      visible,
    });
  };
};
export const setFooterActionButtonVisible = visible => {
  return (dispatch, getState) => {
    if (visible === getState().landing.isFooterActionButtonVisible) return;
    dispatch({
      type: FOOTER_ACTION_BUTON_VISIBLE,
      visible,
    });
  };
};

export const setHeroActionButtonVisible = visible => {
  return (dispatch, getState) => {
    if (visible === getState().landing.isHeroActionButtonVisible) return;
    dispatch({
      type: HERO_ACTION_BUTON_VISIBLE,
      visible,
    });
  };
};

// Old landing mennu
export const setIndex = index => {
  return (dispatch, getState) => {
    const MIN = getState().landing.MIN;
    const MAX = getState().landing.MAX;
    if (index < MIN) return;
    if (index > MAX) return;
    dispatch({
      type: SET_INDEX,
      index,
    });
  };
};

export const onMenuClick = x => {
  return (dispatch, getState) => {
    const isMenuOpen = x ?? !getState().landing.isMenuOpen;
    dispatch({
      type: ON_MENU_CLICK,
      isMenuOpen,
    });
  };
};
