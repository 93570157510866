import { DEV } from "../../config/firebaseConfig";

import {
  SET_INDEX,
  ON_MENU_CLICK,
  // SHOW_ACTION_BAR,
  HERO_ACTION_BUTON_VISIBLE,
  FOOTER_ACTION_BUTON_VISIBLE,
  SCARCITY_ACTION_BUTON_VISIBLE,
} from "../actions/landingActions";

const initState = {
  index: 0,
  MIN: 0,
  MAX: 4,
  isMenuOpen: false,
  // showActionBar: false,
  isHeroActionButtonVisible: true,
  isFooterActionButtonVisible: false,
  isScarcityActionButtonVisible: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case SCARCITY_ACTION_BUTON_VISIBLE:
      DEV && console.log(SCARCITY_ACTION_BUTON_VISIBLE, action.visible);
      return {
        ...state,
        isScarcityActionButtonVisible: action.visible,
      };
    case FOOTER_ACTION_BUTON_VISIBLE:
      DEV && console.log(FOOTER_ACTION_BUTON_VISIBLE, action.visible);
      return {
        ...state,
        isFooterActionButtonVisible: action.visible,
      };
    case HERO_ACTION_BUTON_VISIBLE:
      DEV && console.log(HERO_ACTION_BUTON_VISIBLE, action.visible);
      return {
        ...state,
        isHeroActionButtonVisible: action.visible,
      };
    // case SHOW_ACTION_BAR:
    //   DEV && console.log(SHOW_ACTION_BAR);
    //   return {
    //     ...state,
    //     showActionBar: action.showActionBar,
    //   };
    case SET_INDEX:
      DEV && console.log(SET_INDEX);
      return {
        ...state,
        index: action.index,
      };
    case ON_MENU_CLICK:
      DEV && console.log(ON_MENU_CLICK);
      return {
        ...state,
        isMenuOpen: action.isMenuOpen,
      };

    default:
      return state;
  }
};
