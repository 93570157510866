import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { onMenuClick } from "../../../../store/actions/landingActions";
import NavButtons from "../layouts/NavButtons";

const useStyles = makeStyles(theme => ({
  blur: {
    willChange: "transition",
    transition: "opacity 1s",
    backdropFilter: `blur(10px)`,
    opacity: ({ isMenuOpen }) => (isMenuOpen ? 1 : 0),
    pointerEvents: "none",
    zIndex: 999,
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
  menu: {
    zIndex: 1000,
    background: "#fff8",
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    right: 0,
    "-webkit-backface-visibility": "hidden",
    transition: "transform .5s",
    willChange: "transform",
    transform: ({ isMenuOpen }) => `translateY(${isMenuOpen ? 0 : 100}%)`,

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&>*": {
      textAlign: "center",
      color: theme.palette.primary[800],
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 80,
      fontSize: 20,
    },
  },
}));

const DrawerMenu = () => {
  const isMenuOpen = useSelector(store => store.landing.isMenuOpen);
  const dispatch = useDispatch();
  const classes = useStyles({ isMenuOpen });

  const closeMenu = () => dispatch(onMenuClick(false));

  return (
    <>
      <div className={classes.blur} />
      <div className={classes.menu}>
        <NavButtons showActive closeMenu={closeMenu} />
      </div>
    </>
  );
};

export default DrawerMenu;
