import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    padding: 36,
    [theme.breakpoints.up(767)]: {
      padding: 90,
    },
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  imageTint: {
    width: "100%",
    height: "100%",
    background: ({ isLoaded }) =>
      isLoaded ? theme.palette.primary[100] : "#EBEFF1",
    transition: "background 4s",
    transitionDelay: 1,
    position: "relative",
  },
  image: {
    width: "100%",
    height: "100%",
    backgroundImage: "url('/img/bg-mobile.png')",
    [theme.breakpoints.up(767)]: {
      backgroundImage: "url('/img/bg-desktop.png')",
    },
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: ({ isLoaded }) => (isLoaded ? 0.2 : 0.0),
    transition: "opacity .5s",
  },
  menuImage: {
    width: "100%",
    height: "100%",

    [theme.breakpoints.up(767)]: {
      backgroundImage: "url('/img/bg-desktop.png')",
    },
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: ({ isLoaded }) => (isLoaded ? 0.2 : 0.0),
    transition: "opacity .5s",
  },
  scrollBar: {
    [theme.breakpoints.down(767)]: {
      display: "none",
    },
    width: ({ index }) => `${25 * index}%`,
    willChange: "width",
    transition: "width .5s",
    height: 4,
    position: "absolute",
    bottom: 0,
    left: 0,
    background: theme.palette.primary.main,
  },
}));

const BackgroundPaper = () => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const index = useSelector(state => state.landing.index);

  const menuRoute = useRouteMatch("/menu");

  const classes = useStyles({ isLoaded, index });

  React.useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.imageTint}>
        {menuRoute ? (
          <div className={classes.menuImage}></div>
        ) : (
          <div className={classes.image}></div>
        )}
        <div className={classes.scrollBar}></div>
      </div>
      {/* <div className={classes.tint}></div> */}
    </div>
  );
};

export default BackgroundPaper;
