import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, ButtonGroup } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 1050,
    position: "absolute",
    top: 0,
    right: 0,
    //padding: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 36,
    width: 86,
    "&>*": {
      "&>*": {
        color: theme.palette.primary[800],
      },
    },
  },

  justDesktop: {
    [theme.breakpoints.down(767)]: {
      display: "none",
    },
  },
  justMobile: {
    [theme.breakpoints.up(767)]: {
      display: "none",
    },
  },
}));

const LanguageButtons = ({ justMobile = false, justDesktop = false }) => {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const useEn = () => {
    i18n.changeLanguage("en");
  };
  const useNo = () => {
    i18n.changeLanguage("no");
  };
  return (
    <div
      className={[
        classes.root,
        justMobile && classes.justMobile,
        justDesktop && classes.justDesktop,
      ].join(" ")}
    >
      <ButtonGroup
        variant="text"
        size="small"
        aria-label="small text button group"
      >
        <Button onClick={useEn}>EN</Button>
        <Button onClick={useNo}>NO</Button>
      </ButtonGroup>
    </div>
  );
};

export default LanguageButtons;
