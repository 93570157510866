import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import NavButtons from "../layouts/NavButtons";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down(767)]: {
      display: "none",
    },
    zIndex: 1000,
    position: "absolute",
    top: 0,
    left: "50%",
    transform: ({ isMenuOpen }) =>
      isMenuOpen ? "translate(-50%, -100%)" : "translate(-50%, 0%)",
    transition: "transform .2s",
    //border: "2px solid blue",
    height: 90,
    display: "grid",
    gridGap: 40,
    gridAutoFlow: "column",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    justifyContent: "center",
    alignItems: "center",
    "&>*": {
      textAlign: "center",
      color: theme.palette.primary[800],
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      //border: "1px solid red",
      height: 40,
      padding: "0 10px",
      borderBottom: "2px solid transparent",
      transition: "border .5s",
      "&:hover": {
        transition: "border .1s",
        borderBottom: "2px solid " + theme.palette.primary.main,
      },
    },
  },
}));

const Navbar = () => {
  const isMenuOpen = useSelector(state => state.landing.isMenuOpen);
  const classes = useStyles({ isMenuOpen });
  return (
    <nav className={classes.root}>
      <NavButtons showActive />
    </nav>
  );
};

export default Navbar;
