import React from "react";

//import { MyTextField as TextField } from "../../../styledComponents";
import { Button, CircularProgress, TextField } from "@material-ui/core";

import ErrorHelperText from "./AuthLayout_ErrorHelperText";

import {
  setStep,
  setPhoneNumber,
  setErrorCode,
  setRecaptchaVerifier,
  requestVerificationCode,
} from "../../../../store/actions/authActions";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import { useFirebase } from "react-redux-firebase";

import { DEV } from "../../../../config/firebaseConfig";

const useStyles = makeStyles(theme => ({
  root: {
    overflow: "hidden",
    width: "100%",
    height: "100%",
    padding: 10,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    "-webkit-backface-visibility": "hidden",
    "&>*": {
      margin: "5px 0",
    },
    //willChange: "transform",
  },
  title: {
    paddingBottom: 10,
    fontWeight: 400,
    fontSize: "1.1rem",
    //lineHeight: 1.1,
    borderBottom: "1px solid " + theme.palette.primary.main + "88",
    // transform: "scaleY(0.9)"
  },
  text: {
    fontWeight: 200,
    fontSize: "0.8rem",
  },
  action: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    right: 0,
    display: "flex",

    flexDirection: "column",
  },
  actionsLine: {
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    "&>*": {
      "&:only-child": {
        marginLeft: "auto",
      },
    },
  },

  progressContainer: {
    position: "relative",
  },
  progress: {
    position: "absolute",
    top: "50%",
    right: "50%",
    marginTop: -12,
    marginRight: -12,
  },
}));

export default () => {
  const classes = useStyles();
  const firebase = useFirebase();
  const dispatch = useDispatch();

  const { phoneNumber, loading, errorCode, step } = useSelector(
    store => store.auth
  );

  const { isEmpty, isLoaded } = useSelector(store => store.firebase.profile);

  React.useEffect(() => {
    if (isLoaded && !isEmpty && step === 0) dispatch(setStep(2));
  }, [isLoaded, isEmpty, dispatch, step]);

  const handleChange = React.useCallback(
    event => {
      // Handle missing country prefix
      const number =
        event.target.value[0] === "0"
          ? "+4" + event.target.value
          : event.target.value;
      // Save number
      dispatch(setPhoneNumber(number));
      errorCode && dispatch(setErrorCode(null));
    },
    [dispatch, errorCode]
  );
  const onVerifyClick = React.useCallback(
    e => {
      e.preventDefault();
      dispatch(
        setRecaptchaVerifier(
          new firebase.auth.RecaptchaVerifier("verify-button", {
            size: "invisible",
            callback: function (response) {
              DEV &&
                console.log(
                  "reCAPTCHA solved, signInWithPhoneNumber allowed.",
                  response
                );
            },
          })
        )
      );
      dispatch(requestVerificationCode({ firebase }));
    },
    [firebase, dispatch]
  );

  return isLoaded && isEmpty ? (
    <form className={classes.root} onSubmit={onVerifyClick}>
      <div className={classes.title}>Introduceți numărul de telefon</div>
      <div className={classes.text}></div>
      <TextField
        autoComplete="off"
        type="tel"
        color="primary"
        value={phoneNumber}
        fullWidth
        variant="outlined"
        margin="dense"
        label="Număr de telefon"
        onChange={handleChange}
        name="phoneNumber"
        helperText={
          !errorCode ? (
            <Disclaimer />
          ) : (
            <ErrorHelperText errorCode={errorCode} />
          )
        }
      />
      <div className={classes.action}>
        <div className={classes.actionsLine}>
          <div className={classes.progressContainer}>
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              id="verify-button"
              type="submit"
            >
              verifică
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.progress} />
            )}
          </div>
        </div>
      </div>
    </form>
  ) : (
    <CircularProgress size={40} className={classes.progress} />
  );
};

const DISCLAIMER_STYLE = {
  fontWeight: 100,
  fontSize: ".6rem",
  transform: "scaleY(0.9)",
  lineHeight: 1.1,
};
const Disclaimer = () => {
  return (
    <span style={DISCLAIMER_STYLE}>
      * Prin apăsarea butonului VERIFICĂ, confirmați că sunteți de acord cu
      primirea unui SMS. În funcție de operatorul dvs. ar putea fi aplicate
      tarife pentru mesaje și date.
    </span>
  );
};
