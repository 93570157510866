import React from "react";
import BackgroundPaper from "../layouts/BackgroundPaper";
import Copyright from "./Copyright";
import DrawerMenu from "./DrawerMenu";
import HomeButton from "./HomeButton";
import LanguageButtons from "./LanguageButtons";
import MenuButton from "./MenuButton";
import Navbar from "./Navbar";
import SocialMedia from "./SocialMedia";

const Overlays = () => {
  return (
    <>
      <BackgroundPaper />
      <DrawerMenu />
      <Navbar />
      <MenuButton />
      <HomeButton />
      <LanguageButtons />
      <SocialMedia />
      <Copyright />
    </>
  );
};

export default Overlays;
