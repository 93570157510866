import React from "react";

import EnterPhoneNumber from "./AuthLayout_Phone";
import Enter6DigitsCode from "./AuthLayout_6DigitsCode";
import EnterUserDetails from "./AuthLayout_UserDetails";

import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    //backgroundColor: theme.palette.type === "dark" ? "#8881" : "#fff5", // "#fffa",
    marginBottom: 10,
    padding: "0 10px",
    height: 320,
    width: 300,
    position: "relative",
    overflow: "hidden",
  },
  steps: {
    width: "100%",
    height: "100%",
    "-webkit-backface-visibility": "hidden",
    transition: "transform 0.5s",
    position: "absolute",
    top: 0,
    left: 0,
    overflow: "hidden",
  },
  noTransition: {
    "-webkit-backface-visibility": "hidden",
    transition: "none",
  },
  transformToLeft: {
    "-webkit-backface-visibility": "hidden",
    transform: "translateX(-200%)",
  },
  transformToRight: {
    "-webkit-backface-visibility": "hidden",
    transform: "translateX(100%)",
  },
}));

export default ({ closeAfterLogin }) => {
  const classes = useStyles();
  const step = useSelector(store => store.auth.step);

  return (
    <div className={classes.root}>
      <div
        className={classes.steps + " " + (step > 0 && classes.transformToLeft)}
      >
        <EnterPhoneNumber />
      </div>
      <div
        className={
          classes.steps +
          " " +
          (step === 0 && classes.noTransition) +
          " " +
          (step < 1
            ? classes.transformToRight
            : step > 1 && classes.transformToLeft)
        }
      >
        <Enter6DigitsCode />
      </div>
      <div
        className={
          classes.steps +
          " " +
          (step < 2
            ? classes.transformToRight
            : step > 2 && classes.transformToLeft)
        }
      >
        <EnterUserDetails {...{ closeAfterLogin }} />
      </div>
    </div>
  );
};
