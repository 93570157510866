import { DEV } from "../../config/firebaseConfig";

import { SET_MENUPAGE_INDEX } from "./../actions/appActions";

const initState = {
  menuIndex: 0,
};

export default (state = initState, action) => {
  switch (action.type) {
    case SET_MENUPAGE_INDEX:
      DEV && console.log(SET_MENUPAGE_INDEX, action);
      return {
        ...state,
        menuIndex: action.index,
      };

    default:
      return state;
  }
};
