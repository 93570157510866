import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import CopyrightIcon from "@material-ui/icons/Copyright";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down(767)]: {
      display: "none",
    },
    zIndex: 1000,
    position: "absolute",
    bottom: 0,
    left: 90,
    height: 90,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  copyright: {
    fontSize: 12,
    fontFamily: "courier",
    color: theme.palette.primary[900],
    letterSpacing: 5,
  },
  c: {
    color: theme.palette.primary[900],
  },
}));

let timer;
const year = new Date().getFullYear();

const Copyright = () => {
  const classes = useStyles();
  const history = useHistory();

  const [x, setX] = useState(0);
  useEffect(() => {
    x === 10 && history.push("/admin");
    clearTimeout(timer);
    timer = setTimeout(() => {
      setX(0);
    }, 1000);
    return () => clearTimeout(timer);
  }, [x, history]);

  const onClick = () => setX(x => x + 1);

  return (
    <div className={classes.root}>
      <code className={classes.copyright}>
        <IconButton size="small" onClick={onClick}>
          <CopyrightIcon fontSize="small" className={classes.c} />
        </IconButton>{" "}
        {year} | Pizzeria Melvin
      </code>
    </div>
  );
};

export default Copyright;
