import red from "@material-ui/core/colors/red";
import pink from "@material-ui/core/colors/pink";
import purple from "@material-ui/core/colors/purple";
import deepPurple from "@material-ui/core/colors/deepPurple";
import indigo from "@material-ui/core/colors/indigo";
import blue from "@material-ui/core/colors/blue";
import lightBlue from "@material-ui/core/colors/lightBlue";
import cyan from "@material-ui/core/colors/cyan";
import teal from "@material-ui/core/colors/teal";
import green from "@material-ui/core/colors/green";
import lightGreen from "@material-ui/core/colors/lightGreen";
import lime from "@material-ui/core/colors/lime";
import yellow from "@material-ui/core/colors/yellow";
import amber from "@material-ui/core/colors/amber";
import orange from "@material-ui/core/colors/orange";
import deepOrange from "@material-ui/core/colors/deepOrange";
import brown from "@material-ui/core/colors/brown";
import gray from "@material-ui/core/colors/grey";
import blueGray from "@material-ui/core/colors/blueGrey";

const colors = [
  {
    id: "red",
    //name: "Red",
    name: "Roșu",
    import: red,
  },
  {
    id: "pink",
    //name: "Pink",
    name: "Roz",
    import: pink,
  },
  {
    id: "purple",
    //name: "Purple",
    name: "Mov",
    import: purple,
  },
  {
    id: "deep-purple",
    //name: "Deep Purple",
    name: "Mov Inchis",
    import: deepPurple,
  },
  {
    id: "indigo",
    name: "Indigo",
    import: indigo,
  },
  {
    id: "blue",
    //name: "Blue",
    name: "Albastru",
    import: blue,
  },
  {
    id: "light-blue",
    // name: "Light Blue",
    name: "Albastru Deschis",
    import: lightBlue,
  },
  {
    id: "cyan",
    name: "Cyan",
    // name: "Turcoaz",
    import: cyan,
  },
  {
    id: "teal",
    //name: "Teal",
    name: "Turcoaz",
    import: teal,
  },
  {
    id: "green",
    //name: "Green",
    name: "Verde",
    import: green,
  },
  {
    id: "light-green",
    //name: "Light Green",
    name: "Verde Deschis",
    import: lightGreen,
  },
  {
    id: "lime",
    name: "Lime",
    import: lime,
  },
  {
    id: "yellow",
    //name: "Yellow",
    name: "Galben",
    import: yellow,
  },
  {
    id: "amber",
    //name: "Amber",
    name: "Chihlimbar",
    import: amber,
  },
  {
    id: "orange",
    name: "Orange",
    import: orange,
  },
  {
    id: "deep-orange",
    //name: "Deep Orange",
    name: "Orange Inchis",
    import: deepOrange,
  },
  {
    id: "brown",
    //name: "Brown",
    name: "Maro",
    import: brown,
  },
  {
    id: "gray",
    // name: "Gray",
    name: "Gri",
    import: gray,
  },
  {
    id: "blue-gray",
    // name: "Blue Gray",
    name: "Albastru fad",
    import: blueGray,
  },
];

export default colors;
