import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import LogoTransparent from "../../SVGs/LogoTransparent";

const useStyles = makeStyles(theme => ({
  root: {
    width: ({ size }) => size,
    height: ({ size }) => size,
    margin: ({ size }) => size / 10,
  },
  animate: {
    animation: `$loadingSlow 2s infinite`,
  },
  "@keyframes loadingSlow": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "10%": {
      transform: "rotate(5deg)",
    },
    "20%": {
      transform: "rotate(-5deg)",
    },
    "30%": {
      transform: "rotate(5deg)",
    },
    "40%": {
      transform: "rotate(-5deg)",
    },
    "50%": {
      transform: "rotate(5deg)",
    },
    "60%": {
      transform: "rotate(-5deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

export const LogoWithSize = ({ size = 100, noAnimation }) => {
  const classes = useStyles({ size });

  return (
    <LogoTransparent
      className={[classes.root, !noAnimation && classes.animate].join(" ")}
    />
  );
};

export const SVGLogoWithSize = ({ size = 100, noAnimation }) => {
  const classes = useStyles({ size });

  return (
    <div className={[classes.root, !noAnimation && classes.animate].join(" ")}>
      <img
        loading="lazy"
        src="/svg/logo100.svg"
        alt="logo"
        width="100%"
        height="100%"
      />
    </div>
  );
};
