import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: 5,
  },
  title: {
    fontSize: 14,
    display: "flex",
    alignItems: "flex-end",
    fontWeight: 500,
  },
  dots: {
    margin: "0 5px 5px 5px",
    flex: 1,
    borderBottom: "2px dotted #555",
  },
  smallText: {
    fontSize: 12,
    fontWeight: 200,
  },
  dietary: {
    margin: "0 10px",
  },
}));

const MenuProduct = ({ product }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {product.name}
        {product.vegan && (
          <img
            className={classes.dietary}
            src="https://firebasestorage.googleapis.com/v0/b/pizzeria-melvin.appspot.com/o/vegan.svg?alt=media&token=2ebc743b-34ee-40fa-8cfb-a7d5cea3d4ae"
            alt="vegan"
            width={24}
            height={24}
          />
        )}
        {product.vegetarian && (
          <img
            className={classes.dietary}
            src="https://firebasestorage.googleapis.com/v0/b/pizzeria-melvin.appspot.com/o/vegetarian.svg?alt=media&token=a8c43ce0-6b72-495c-8d2d-15f15fc17070"
            alt="vegetarian"
            width={26}
            height={26}
          />
        )}
        <span className={classes.smallText}>{product.allergens}</span>
        <span className={classes.dots}></span>
        {product.price}
      </div>
      <div className={classes.smallText}>{product.ingredients}</div>
    </div>
  );
};

export default MenuProduct;
