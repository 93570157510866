import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import B2bForm from "../elements/layouts/B2bForm";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    overflow: "hidden",
  },
  container: {
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const B2B = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <B2bForm />
      </div>
    </div>
  );
};

export default B2B;
